<template>
  <div class="magazine-wrapper">
    <header class="magazine-header">
      <ClientOnly>
        <h1 class="magazine-header__title">
          <nuxt-link to="/">
            <img
              src="/img/top/logo.svg"
              width="207"
              height="19"
              alt="WARC AGENT"
            />
          </nuxt-link>
        </h1>
        <div class="magazine-header__button">
          <ButtonsMagazineButton
            :text="isPc ? '無料カウンセリング' : '無料相談'"
            type="link"
            :path="fullUrl('/registration')"
          />
        </div>
        <div class="magazine-header__button">
          <ButtonsMagazineButton
            :text="isPc ? '法人の方はこちら' : '法人の方'"
            type="link"
            path="/contact"
            colorType="secondary"
          />
        </div>
      </ClientOnly>
    </header>
    <slot />
    <footer class="magazine-footer">
      <div class="magazine-footer__wrapper">
        <ul class="links">
          <li v-for="(l, key) in LEFT_LINKS" :key="key" class="links__list">
            <nuxt-link
              :to="l.isBlank ? l.path : fullUrl(l.path)"
              class="link-item"
              :target="l.isBlank ? '_blank' : '_self'"
            >
              <img
                src="/img/articles/categories/icon-arrow-right.svg"
                width="16"
                height="16"
                alt=""
              />
              <span class="link-item__label">{{ l.label }}</span>
            </nuxt-link>
          </li>
        </ul>
        <ul class="links">
          <li v-for="(l, key) in CENTER_LINKS" :key="key" class="links__list">
            <nuxt-link
              :to="l.isBlank ? l.path : fullUrl(l.path)"
              class="link-item"
              :target="l.isBlank ? '_blank' : '_self'"
            >
              <img
                src="/img/articles/categories/icon-arrow-right.svg"
                width="16"
                height="16"
                alt=""
              />
              <span class="link-item__label">{{ l.label }}</span>
            </nuxt-link>
          </li>
        </ul>
        <ul class="links">
          <li v-for="(l, key) in RIGHT_LINKS" :key="key" class="links__list">
            <nuxt-link
              :target="l.isBlank ? '_blank' : '_self'"
              :to="l.isBlank ? l.path : fullUrl(l.path)"
              class="link-item"
            >
              <img
                src="/img/articles/categories/icon-arrow-right.svg"
                width="16"
                height="16"
                alt=""
              />
              <span class="link-item__label">{{ l.label }}</span>
            </nuxt-link>
          </li>
        </ul>
        <div class="footer-container">
          <div class="article__footer__item">
            <nuxt-link to="/" class="article__footer__logo">
              <img
                src="/img/top/logo.svg"
                width="207"
                height="19"
                alt="WARC AGENT"
              />
            </nuxt-link>
            <p class="article__footer__description"></p>
          </div>
        </div>
        <span class="magazine-footer__copy">&copy; WARC.inc</span>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
const LEFT_LINKS = [
  {
    label: '経理',
    path: '/articles/categories/accounting',
    isBlank: false,
  },
  {
    label: '労務',
    path: '/articles/categories/labor',
    isBlank: false,
  },
  {
    label: 'バックオフィス',
    path: '/articles/categories/backoffice',
    isBlank: false,
  },
  {
    label: '管理',
    path: '/articles/categories/management',
    isBlank: false,
  },
  {
    label: '財務',
    path: '/articles/categories/finance',
    isBlank: false,
  },
  {
    label: '人事',
    path: '/articles/categories/human_resources',
    isBlank: false,
  },
  {
    label: '法務',
    path: '/articles/categories/legal',
    isBlank: false,
  },
  {
    label: '会計',
    path: '/articles/categories/bookkeeping',
    isBlank: false,
  },
  {
    label: 'スタートアップ',
    path: '/articles/categories/startup',
    isBlank: false,
  },
  {
    label: '監査法人',
    path: '/articles/categories/auditing_firm',
    isBlank: false,
  },
  {
    label: '経営企画',
    path: '/articles/categories/business_planning',
    isBlank: false,
  },
] as const;

const CENTER_LINKS = [
  {
    label: 'IR',
    path: '/articles/categories/investor_relations',
    isBlank: false,
  },
  {
    label: '広報',
    path: '/articles/categories/public_relations',
    isBlank: false,
  },
  {
    label: 'CFO',
    path: '/articles/categories/chief_financial_officer',
    isBlank: false,
  },
  {
    label: '情シス',
    path: '/articles/categories/information_system',
    isBlank: false,
  },
  {
    label: '資格',
    path: '/articles/categories/certification',
    isBlank: false,
  },
  {
    label: '知識',
    path: '/articles/categories/knowledges',
    isBlank: false,
  },
  {
    label: '制度',
    path: '/articles/categories/system',
    isBlank: false,
  },
  {
    label: 'BtoB',
    path: '/articles/categories/b2b',
    isBlank: false,
  },
  {
    label: 'その他',
    path: '/articles/categories/others',
    isBlank: false,
  },
  {
    label: '導入事例',
    path: '/articles/categories/case_studies',
    isBlank: false,
  },
  {
    label: 'ニュース',
    path: '/articles/categories/news',
    isBlank: false,
  },
] as const;

const RIGHT_LINKS = [
  {
    label: 'WARCエージェント マガジン',
    path: '/articles',
    isBlank: false,
  },
  {
    label: 'プライバシーポリシー',
    path: 'https://corp.warc.jp/privacy-policy/',
    isBlank: true,
  },
  {
    label: '会社概要',
    path: 'https://corp.warc.jp/',
    isBlank: true,
  },
  {
    label: 'WARCエージェント',
    path: '/',
    isBlank: false,
  },
] as const;

const isPc = useState('isPc', () => false);
const checkWindowWidth = () => {
  isPc.value = window.innerWidth > 767;
};

onMounted(() => {
  checkWindowWidth();
  window.addEventListener('resize', checkWindowWidth);
});
</script>
